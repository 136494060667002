import React from "react"
import { graphql } from "gatsby"
import Work from "components/works"
import Layout from "../components/layout"
import Footer from "../components/footer"
import SEO from "../components/seo"

export const query = graphql`
  query {
    allFile(
      filter: { sourceInstanceName: { eq: "content" }, name: { eq: "works" } }
    ) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              lhp
              lhs
              intro
              work {
                blurbs {
                  category
                  content
                  title
                  date_time
                  tech
                  quote
                  quote_au_da
                  image {
                    childImageSharp {
                      fluid(maxWidth: 1075, quality: 72) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`

class Works extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: false,
      caseA: true,
      caseC: false,
      caseP: false,
      top: false,
    }
  }

  toggleWorks(key) {
    if (key === "all") {
      this.setState({
        caseA: true,
        caseC: false,
        caseP: false,
      })
    } else if (key === "client") {
      this.setState({
        caseA: false,
        caseC: true,
        caseP: false,
      })
    } else if (key === "personal") {
      this.setState({
        caseA: false,
        caseC: false,
        caseP: true,
      })
    }
  }
  toggleTop() {
    if (!this.state.top) {
      this.setState({
        top: true,
      })
      // Set Menu State
    } else {
      this.setState({
        top: false,
      })
    }
  }

  sortByCategory(data, cat) {
    return data.filter(single => {
      return single.category == cat
    })
  }
  render() {
    const data = this.props.data.allFile.edges[0].node.childMarkdownRemark
      .frontmatter
    return (
      <div className="body">
        <Layout link="works">
          <SEO
            title="My works"
            description="Hi, I am Nwachukwu chibuike, check out my programming and web development works on this page. I would love to get your feedback!"
            keywords="Nwachukwu chibuike, Chibuike, Nwachukwu, Web developer nigeria, Chibuike works, Chibuike Portfolio,"
          />

          <main id="work">
            <h1 className="lg-heading">
              {data.lhp}&nbsp;
              <span className="text-secondary">{data.lhs}</span>
            </h1>
            <h2 className="sm-heading">{data.intro}</h2>
            <section className="project-wrapper">
              <center className="links">
                <span
                  className={this.state.caseA ? "link active" : "link"}
                  onClick={() => this.toggleWorks("all")}
                >
                  {" "}
                  All{" "}
                </span>
                <span
                  className={this.state.caseC ? "link active" : "link"}
                  onClick={() => this.toggleWorks("client")}
                >
                  {" "}
                  Client{" "}
                </span>
                <span
                  className={this.state.caseP ? "link active" : "link"}
                  onClick={() => this.toggleWorks("personal")}
                >
                  {" "}
                  Personal
                </span>
              </center>

              {this.state.caseA ? (
                <div className="projects">
                  <Work gridItems={data.work.blurbs} />
                </div>
              ) : (
                ""
              )}

              {this.state.caseC ? (
                <div className="projects">
                  <Work
                    gridItems={this.sortByCategory(data.work.blurbs, "client")}
                  />
                </div>
              ) : (
                ""
              )}

              {this.state.caseP ? (
                <div className="projects">
                  <Work
                    gridItems={this.sortByCategory(
                      data.work.blurbs,
                      "personal"
                    )}
                  />
                </div>
              ) : (
                ""
              )}
            </section>
          </main>
        </Layout>
        <Footer />
      </div>
    )
  }
}

export default Works
