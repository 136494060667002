import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Img from "gatsby-image"

class Works extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showMenu: false,
      caseA: true,
      caseC: false,
      caseP: false,
      top: [],
    }
  }

  componentDidMount() {
    let gridItem = this.props
    this.setDefault()
  }
  setDefault() {
    this.props.gridItems.forEach((element, index) => {
      this.setState(prevState => {
        const newItems = [...prevState.top]
        newItems[index] = false
        return { top: newItems }
      })
    })
  }
  toggleTop(key) {
    this.setDefault()
    if (!this.state.top[key]) {
      this.setState(prevState => {
        const newItems = [...prevState.top]
        newItems[key] = true
        return { top: newItems }
      })
      // Set Menu State
    } else {
      this.setState(prevState => {
        const newItems = [...prevState.top]
        newItems[key] = false
        return { top: newItems }
      })
    }
  }

  render() {
    return (
      <>
        {this.props.gridItems.map((item, index) => (
          <div className="item" key={index}>
            <div className={this.state.top[index] ? "top close" : "top "}>
              <h4>{item.title}</h4>
              {item.tech}
            </div>
            <div className={this.state.top[index] ? "below close" : "below"}>
              <Link to="/works/single" state={{ item }}>
                <Img
                  fluid={item.image.childImageSharp.fluid}
                  alt={item.title}
                  key={index}
                />
              </Link>
            </div>
            <div className="cross" onClick={() => this.toggleTop(index)}>
              {this.state.top[index] ? "x" : "i"}
            </div>
          </div>
        ))}
      </>
    )
  }
}

export default Works
